.register {
  background-color: rgba(237, 236, 193, 0.18);
  color: #353D2F;
  direction: rtl;
}

.register-form {
  margin-inline: auto;
  margin-block: 3.8125em;
  margin-left: auto;
  margin-right: auto;

  .form-title {
    font-size: 1.062em;
    margin-bottom: 4.235em;
  }

  .field-wrapper {
    margin-bottom: 1.875em;

    input,
    select {
      height: 2.333em;
      width: 17.25em;
      box-sizing: border-box;
      font-size: 0.75em;
      &[type="tel"] {
        direction: rtl;
      }
    }
  }

  .field-terms {

    a,
    .field-terms-underline {
      padding-inline-start: 0.5em;
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .react-select-container {
    width: 17.25em;
    font-size: 0.75em;
  }

  .react-select-control {
    min-height: 0;
    border-color: rgb(118, 118, 118);
    border-radius: 2px;
  }

  .react-select-value-container,
  .react-select-input {
    padding: 0;
    margin: 0;
    padding-inline: 2px;
  }

  .field-cb-wrapper {
    margin-bottom: 1.3125em;

    label {
      display: flex;
      align-items: center;
      font-size: 0.75em;

    }

    input {
      width: 1.08em;
      height: 1.08em;
      font-size: inherit;
      margin-left: 0.75em;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid #353D2F;
      outline: none;
    }

    input:checked {
      background-color: #353D2F;
      border: 1px solid #353D2F;
    }

    input:focus {
      outline: none;
    }
  }

  .error-wrapper {
    position: absolute;
    font-size: 0.5625em;
    padding-top: 0.5625em;
    color: #be5128;
  }

  .error-submit-wrapper {
    transform: translate(-50%, 0);
    left: 50%;
  }

  .register-btn {
    margin-top: 3.1875em;
    font-size: 0.75em;
    background-color: transparent;
    border: 1px solid #353D2F;
    border-radius: 3.33em;
    color: inherit;
    height: 2.166em;
    width: 10.916em;
    display: block;
    font-family: inherit;
    margin-inline: auto;
    //for safari
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .register-btn:hover {
    background-color: #353D2F;
    color: #FFFEF8;
  }
}

.register-process {
  margin-inline: auto;
  //for safari
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.8125em;

  .register-process-title {
    font-size: 1.062em;
    margin-bottom: 4.235em;
  }

  .register-process-error {
    text-align: center;
  }

  .register-process-err-btn {
    cursor: pointer;
    margin-top: calc(100vh * 30 / 100);
    font-size: 0.75em;
    background-color: transparent;
    border: 1px solid #353D2F;
    border-radius: 3.33em;
    color: inherit;
    height: 2.166em;
    width: 10.916em;
    display: block;
    font-family: inherit;
    margin-inline: auto;
    //for safari
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app-loader {
    margin-inline: auto;
    //for safari
    margin-left: auto;
    margin-right: auto;
    width: 2em;
    height: 2em;
  }
}


