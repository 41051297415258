.app {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('../../assets/images/bg-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFEF8;


  @media screen and (min-width: 767px) {
    background-image: url('../../assets/images/bg-desktop.png');
  }
}

.app-slogan {
  font-size: 1.3125em;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 450;
  letter-spacing: 0.1em;

  @media screen and (min-width: 767px) {
    font-size: 2.4em;
  }
}

.app-bottom {
  display: flex;
  padding-bottom: 5.4375em;
  align-items: flex-end;
  justify-content: center;
  height: 4em;
}

.accessibility-statement-url {
  font-size: 1.3em;
  margin-left: 1.3em;
  padding-bottom: 1em;
  
  a {
    color: #FFFEF8;
  }
}

.membership-btn {
  font-size: 1.125em;
  background-color: transparent;
  border: 1px solid #FFFEF8;
  border-radius: 3.33em;
  color: inherit;
  height: 2.38em;
  width: 11.5em;
  display: block;
  font-family: inherit;

  @media screen and (min-width: 767px) {
    font-size: 1.375em;
  }
}

.membership-btn:hover {
  background-color: #FFFEF8;
  color: #353D2F;
}

.membership-msg {
  font-size: 1.125em;
  padding: 0 4.5em;
  text-align: center;
  a {
    color: #353d2f;
  }

  @media screen and (min-width: 767px) {
    font-size: 1.375em;
  }
}
.modal-hp {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  .modal-content {
    margin: 0 1em;
    padding-bottom: 1em;
    min-height: 240px;
    @media screen and (min-width: 767px) {
      height: 280px;
      width: 600px;
      margin: 0 auto;
      padding: 0;
    }
    .modal-body {
      padding: 0 3vw;
      font-size: 1.5em;
    }
  }
}
