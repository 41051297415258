.accessibility-statement {
  background-color: rgba(237, 236, 193, 0.18);
  color: #353D2F;
  font-size: 1.5em;

  .content {
    width: 70%;
    margin: 0 auto;
    direction: rtl;
  }
}